import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslationService} from '../../../core/services/translation.service';
import {BaseDataService} from '../../../core/services/base-data.service';
import {SubChangeService} from '../../../data/services/sub-change.service';
import {HupSubscriptionsService} from '../../../data/services/hup-subscriptions.service';
import {ErrorService} from '../../../core/components/general-errors/error.service';
import {MatStepper} from '@angular/material/stepper';
import {HupSubscription} from '../../../data/models/subscription.model';
import {ActivatedRoute} from '@angular/router';
import {SubChangeType, SubChangeTypeEnum} from '../../../data/models/sub-change-type.model';
import {BaseData} from '../../../data/models/base-data.model';
import {ConfigService} from '../../../core/services/config.service';
import {Observable} from 'rxjs';
import {StepperOrientation} from '@angular/cdk/stepper';
import {BreakpointObserver} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';
import {SnackbarService} from '../../../core/services/snackbar.service';
import {NavigationCommand, NavigationService} from '../../../data/services/navigation.service';
import {OfferPrice} from '../../../data/models/price.model';
import {DonationPool} from '../../../data/models/donation-pool.model';
import {getHouseNo} from "../../../core/address-utils";
import {SubChange} from "../../../data/models/sub-change.model";

@Component({
  selector: 'app-sub-change-edit',
  templateUrl: './sub-change-edit.component.html',
  styleUrls: ['./sub-change-edit.component.scss']
})
export class SubChangeEditComponent implements OnInit {
  isMobile: boolean = false;  // TODO: Berechnung
  minDate: Date = new Date();
  sub: HupSubscription;
  subChangeTypeForm: FormGroup;
  collectDataForm: FormGroup;
  forwardDataForm: FormGroup;
  donationDataForm: FormGroup;
  ePaperDataForm: FormGroup;
  intermissionDataForm: FormGroup;
  errorsArr: string[] = [];
  warningsArr: string[] = [];
  isLoadingSub: boolean = false;
  isLoading: boolean = false;
  countries: BaseData[] = [];
  salutations: BaseData[] = [];
  titles: BaseData[] = [];
  public donationPools: DonationPool[];
  donationBoolError: boolean = false;
  enableAdditionalExemplar: boolean;

  offerPrice: OfferPrice;
  priceCheckError = false;
  subChangeTypes: SubChangeType[] = [];
  changeable: boolean = true;
  saving: boolean = false;
  subChange;
  showBackToOverviewButton: boolean;
  userHouseNo: string;
  priceCheckFinished: boolean = false;
  referenceFormValues: any;
  referenceSubChangeTypeValues: any;
  formSubmitted = false;
  zeroPrice = '0.0';

  stepperOrientation: Observable<StepperOrientation>;

  constructor(public translationService: TranslationService,
              private formBuilder: FormBuilder,
              private baseDataService: BaseDataService,
              private subChangeService: SubChangeService,
              private hupSubscriptionsService: HupSubscriptionsService,
              private errorService: ErrorService,
              private route: ActivatedRoute,
              private configService: ConfigService,
              private breakpointObserver: BreakpointObserver,
              private snackBar: SnackbarService,
              private navigationService: NavigationService
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));

    this.configService.loadConfig('subchange.enable.additional.exemplar').subscribe(config => {
      if (config != null && config.value != undefined) {
        this.enableAdditionalExemplar = (config.value === '1' || config.value === 'true');
      }
    });

    this.configService.loadConfig('show.backToOverView.button').subscribe(config => {
      if (config && config.value) {
        this.showBackToOverviewButton = (config.value === '1');
      }
    });
  }

  ngOnInit(): void {
    this.subChange = null;

    this.isLoadingSub = true;
    this.countries = this.baseDataService.getBaseData('countrycodes');
    this.salutations = this.baseDataService.getBaseData('clienttypes');
    this.titles = this.baseDataService.getBaseData('titles');

    this.buildForms();

    this.route.paramMap.subscribe((params) => {
      this.hupSubscriptionsService.getSubscription('', params.get('subBackendId')).subscribe(sub => {
        this.sub = sub;
        this.isLoadingSub = false;
        // set value in form?
        this.forwardDataForm.get('subId').patchValue(sub.backendId);

        this.hupSubscriptionsService.getNextDeliveryDate(sub.productCode, sub.variantCode).subscribe(date => {
          if (date) {
            const now = new Date();
            now.setDate(now.getDate() + 1);
            this.minDate = now.getDate() <= date.getDate() ? date : now;
          }
        });

        this.isDonationImpossible(+params.get('subBackendId'), +params.get('changeWebId'), +params.get('changeBackendId'));
      });

      this.changeable = params.get('changeable') !== 'false';
    });
  }

  loadChangeTypesAndSubChange(subBackendId: number, changeWebId: number, changeBackendId: number): void {
    this.subChangeService.getSubChangeTypes(subBackendId).subscribe(typesAllowed => {
      // und was ist mit sammeln...? {key: 1, code: 'COLLECT'},
      if (typesAllowed.donationAllowed && !this.donationBoolError) {
        this.subChangeTypes.push({key: SubChangeTypeEnum.DONATION_POOL, code: 'DONATION', description: 'Spende'});
      }
      if (typesAllowed.epaperReplacementAllowed) {
        this.subChangeTypes.push({key: SubChangeTypeEnum.EPAPER, code: 'EPAPER', description: 'Epaper'});
      }
      if (typesAllowed.forwardAllowed) {
        this.subChangeTypes.push({key: SubChangeTypeEnum.FORWARD, code: 'FORWARD', description: 'Nachlieferung'});
      }
      if (typesAllowed.intermissionAllowed) {
        this.subChangeTypes.push({
          key: SubChangeTypeEnum.INTERMISSION,
          code: 'INTERMISSION',
          description: 'Unterbrechung'
        });
      }
      if (changeWebId > 0 || changeBackendId > 0) {
        this.subChangeService.getSubChange(changeWebId, changeBackendId).subscribe(subChange => {
          this.subChange = subChange;
          this.setFormData(subChange);
          this.disableForms();
        });
      }
    });
  }

  setFormData(subChange): void {
    const typeKey = SubChangeTypeEnum[subChange.changeType];
    let changeType = {};
    this.subChangeTypes.forEach(type => {
      if (type.key.toString() === typeKey.toString()) {
        changeType = type;
      }
    });
    this.subChangeTypeForm.patchValue(subChange);
    this.subChangeTypeForm.get('changeType').patchValue(changeType);
    this.collectDataForm.patchValue(subChange);
    this.forwardDataForm.patchValue(subChange);
    this.donationDataForm.patchValue(subChange);
    this.ePaperDataForm.patchValue(subChange);
    this.intermissionDataForm.patchValue(subChange);
  }

  disableForms(): void {
    if (!this.changeable) {
      this.subChangeTypeForm.disable();
      this.collectDataForm.disable();
      this.forwardDataForm.disable();
      this.donationDataForm.disable();
      this.ePaperDataForm.disable();
      this.intermissionDataForm.disable();
    }
  }

  buildForms(): void {
    this.subChangeTypeForm = this.formBuilder.group({
      validDate: this.formBuilder.group({
        validFrom: ['', Validators.required],
        validUntil: ['', Validators.required],
      }),
      changeType: ['', Validators.required] // -> donation, collectShipment, imermission, epaper, forward?
    });

    this.collectDataForm = this.formBuilder.group({
      // Collection Adresse
    });

    this.forwardDataForm = this.formBuilder.group({
      subId: ['', Validators.required],
      address: this.formBuilder.group({
        country: [''],
        zipcode: [''],
        city: [''],
        street: [''],
        houseNo: [''],
        extraLine: [''],
        houseNoExt: [''],
        stiege: [''],
        stock: [''],
        tuer: [''],
        district: ['']
      }),
      person: this.formBuilder.group({
        title: [''],
        salutation: [''],
        firstName: [''],
        lastName: [''],
        initials: [''],
        middlename: ['']
      }),
      additional: ['']
    });

    // TODO: Andere Imermission Fälle
    this.donationDataForm = this.formBuilder.group({
      // Pool, Privat oder Öffentl.
      // evtl. Adresse eingeben
    });

    this.ePaperDataForm = this.formBuilder.group({
      // ggf. Ausgaben Wahl // TODO: Ausgabenliste
    });

    this.intermissionDataForm = this.formBuilder.group({
      // Keine weiteren Angaben, aber ggf. Berechnung der Erstattung
    });
  }

  onSend(stepper: MatStepper): void {
    this.saving = true;
    const data = this.getFormData();
    this.saving = true;

    if (this.subChange) {
      this.subChangeService.updateSubChange(data, this.subChange.webId, this.subChange.subId, this.subChange.backendId)
        .subscribe(res => {
            this.navigateToOverview();
          },
          error => {
            this.errorsArr = [];
            this.errorService.handleAPIError([
              this.intermissionDataForm,
              this.forwardDataForm,
              this.ePaperDataForm,
              this.collectDataForm,
              this.subChangeTypeForm,
              this.donationDataForm
            ], error.error, this.errorsArr, stepper);
            this.saving = false;
          },
          () => {
            this.saving = false;
          });
    } else {
      this.subChangeService.sendSubChange(data).subscribe(res => {
          this.navigateToOverview();
        }, error => {
          this.errorsArr = [];
          this.errorService.handleAPIError([
            this.intermissionDataForm,
            this.forwardDataForm,
            this.ePaperDataForm,
            this.collectDataForm,
            this.subChangeTypeForm,
            this.donationDataForm
          ], error.error, this.errorsArr, stepper);
          this.saving = false;
        },
        () => this.saving = false);
    }
  }

  private navigateToOverview() {
    this.navigationService.navigateTo(NavigationCommand.SUBCHANGE).then((navigated) => {
      if (navigated) {
        this.snackBar.openSnackbar(this.translationService.getTranslation('saved'));
      }
    });
  }

  getPriceForSubChange(stepper: MatStepper): void {
      if (stepper.steps.get(stepper.selectedIndex) === stepper.steps.last && this.changeable) {
        this.getPriceForType(this.changeable);
    }
  }

  getPriceForType(changeable:boolean) {
    if (changeable) {
      this.isLoading = true;
      const formData = this.getFormData();
      this.priceCheckFinished = false;

      this.submitForm();
      this.resetWarnings();

      if (this.offerPrice) {
        this.offerPrice.price = 0;
      }
      this.subChangeService.getPriceForSubChange(formData).subscribe({
        next: (price) => {
          this.isLoading = false;
          let warning: any = {};

          switch (formData.changeType) {
            case SubChangeTypeEnum.FORWARD: {
              if (price && price.price) {
                this.offerPrice = price;
                warning.infoText = this.translationService.getTranslation('forwarding.new_price') + this.offerPrice.price + ' ' + this.offerPrice?.currency + '.';
              } else {
                warning.infoText = this.translationService.getTranslation('forwarding.zero_price');
              }
              this.warningsArr.push(warning);
              this.priceCheckFinished = true;
              break;
            }

            case 'EPAPER': {
              break;
            }

            case 'DONATION_POOL': {
              break;
            }

            case 'INTERMISSION': {
              break;
            }

            case 'DONATION_WELFARE': {
              break;
            }

            default:
              break;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorsArr = [];
          if (error?.error?.message) {
            const errorObj = JSON.parse(error?.error?.message);
            for (var key in errorObj) {
              this.errorsArr.push(errorObj[key]);
            }
          }
          const message = this.translationService.getTranslation('error_subchanges_price_calculation');
          if (message && message !== '') {
            this.errorsArr.push(message);
          }
          this.priceCheckFinished = true;
          this.priceCheckError = true;
        }
      });
    }
  }

  getFormData(): any {
    const data = {
      ...this.intermissionDataForm.value,
      ...this.forwardDataForm.value,
      ...this.ePaperDataForm.value,
      ...this.collectDataForm.value,
      ...this.subChangeTypeForm.value,
      ...this.donationDataForm.value
    };

    if (this.forwardDataForm.get('person.title').value) {
      data.person.title = this.forwardDataForm.get('person.title').value.key;
    }

    if (this.forwardDataForm.get('person.salutation').value) {
      data.person.salutation = this.forwardDataForm.get('person.salutation').value.key;
    }

    if (this.forwardDataForm.get('address.country').value) {
      data.address.country = this.forwardDataForm.get('address.country').value.key;
    }

    if (this.forwardDataForm.get('person.firstName').value) {
      data.person.firstname = data.person.firstName;
    }

    if (this.forwardDataForm.get('person.lastName').value) {
      data.person.lastname = data.person.lastName;
    }

    if (this.forwardDataForm.get('address.houseNo').value) {
      if (this.forwardDataForm.get('address.country').value.key === 'A' ||
        this.forwardDataForm.get('address.country').value.key === 'AT') {
        data.address.houseno = getHouseNo(data.address.houseNo, data.address.country, data.address.stock, data.address.stiege, data.address.tuer);
      } else {
        data.address.houseno = getHouseNo(data.address.houseNo, data.address.country);
      }
      this.userHouseNo = data.address.houseno;
    }

    if (data?.changeType) {
      data.changeType = data.changeType.key;
    }

    if (this.offerPrice?.price) {
      data.price = this.offerPrice.price;
    }

    if (this.forwardDataForm.get('address.extraLine').value) {
      data.address.addrExtraLine1 = data.address.extraLine;
    }

    if (this.subChangeTypeForm.get('changeType').value.code === 'DONATION' && this.donationPools.length === 1) {
      data.donationPool = this.donationPools[0].key;
    }
    return data;
  }

  /**
   * Define if there is only one donation pool
   */
  isDonationImpossible(subBackendId: number, changeWebId: number, changeBackendId: number): void {
    this.subChangeService.getDonationPools(this.sub.subId).subscribe(
      (dp) => {
        this.donationPools = dp;
        this.donationBoolError = this.donationPools === null || this.donationPools === undefined || this.donationPools.length !== 1;
        if (this.donationPools === null || this.donationPools === undefined) {
          console.debug(this.translationService.getTranslation('subchange.donation.impossible') + ' (no Pools configured)');
        } else if (this.donationPools.length !== 1) {
          console.debug(this.translationService.getTranslation('subchange.donation.impossible') + ' (more then one pool configured)');
        }

        this.loadChangeTypesAndSubChange(subBackendId, changeWebId, changeBackendId);
      },
      (error) => {
        console.error('Error fetching donation pools', error);
      }
    );
  }

  hasFormChanges(): boolean {
    if (!this.formSubmitted) {
      return false;
    }
    return JSON.stringify(this.forwardDataForm.value) != JSON.stringify(this.referenceFormValues) ||
      JSON.stringify(this.subChangeTypeForm.get('validDate').value) != JSON.stringify(this.referenceSubChangeTypeValues);
  }

  submitForm() {
    if(this.forwardDataForm.valid) {
      this.referenceFormValues = {...this.forwardDataForm.value};
      this.referenceSubChangeTypeValues = {...this.subChangeTypeForm.get('validDate').value};
      this.formSubmitted = true;
    }
  }

  resetWarnings() {
      this.warningsArr = [];
  }

  backToOverview(): void {
    //window.location.href = 'https://abo.siegener-zeitung.de';
    this.navigationService.navigateTo(NavigationCommand.SUBCHANGE, null, true);
  }
}
