import {AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef,
  Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild,} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {TranslationService} from '../../../core/services/translation.service';
import {BaseDataService} from '../../../core/services/base-data.service';
import {Subscription} from 'rxjs';
import {Bonus, BonusType} from '../../../data/models/bonus.model';
import {MatDialog} from '@angular/material/dialog';
import {BonusDetailComponent} from './bonus-detail/bonus-detail.component';
import {OfferService} from '../../../data/services/offer.service';
import {Offer} from '../../../data/models/offer.model';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../../../core/components/login/login.service';
import {UserData} from '../../../data/models/user-data.model';
import {HupSubscriptionsService} from '../../../data/services/hup-subscriptions.service';
import {CustomValidators} from '../../../core/validators';
import {BaseData} from '../../../data/models/base-data.model';
import {OfferPrice} from '../../../data/models/price.model';
import {LoginComponent} from '../../../core/components/login/login.component';
import {
  PaymentProvider,
  PaymentTransactionModel
} from '../../../core/components/onlinepayment/paymentTransaction.model';
import {FormOfPayment} from "../../../data/models/formOfPayment.model";
import {ConfigService} from "../../../core/services/config.service";
import {SnackbarService} from "../../../core/services/snackbar.service";
import {CssPropertiesEnum} from "../../../core/models/css-properties.enum";
import {VHostService} from "../../../core/services/v-host.service";
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";
import {MoveService} from "../../../data/services/move.service";
import {PaymentProviderService} from "../../../core/services/payment-provider.service";
import {DatePipe, isPlatformBrowser} from "@angular/common";
import {SubExtendedData} from "../../../data/models/sub-extended-data.model";
import {SubExtendedDataField} from "../../../data/models/sub-extended-data-field";
import {OrderTrackingService} from "../../../core/services/order-tracking.service";
import {GtmEnum} from "../../../core/models/gtm.enum";
import {DomSanitizer} from '@angular/platform-browser';
import {ImageService} from "../../../core/services/image.service";
import {StepperOrientation, StepperSelectionEvent} from "@angular/cdk/stepper";
import {OrderResultModel} from "../../../data/models/orderResult.model";
import {MatStepper} from "@angular/material/stepper";

@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss']
})
export class OfferDetailComponent implements OnInit, AfterViewChecked, OnDestroy, AfterViewInit, AfterContentInit, AfterContentChecked {
  @ViewChild('login') loginComponent: LoginComponent;

  showOnlinePayment: boolean = false;
  needIbanBic: boolean = false;
  needInvoiceMailAddress: boolean = false;
  askForBirthday: boolean = true;
  askForEmail: boolean = true;
  countries: BaseData[] = [];

  CssPropertiesEnum = CssPropertiesEnum;

  billingAddressFilled: boolean = false;
  bonusAddressFilled: boolean = false;

  colAmount: number = 5;
  offer: Offer;
  bonusFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  paymentFormGroup: FormGroup;
  loginFormGroup: FormGroup;

  upsellingFormGroup: FormGroup;
  isEditable: boolean = true;
  offerPrice: OfferPrice[];
  authSub: Subscription;
  isAuthenticated: boolean = false;
  errorsArr: string[] = [];

  isLoadingUserData: boolean = false;
  isLoadingView: boolean = false;
  userData: UserData;

  maxPoints: number = 12; // TODO: load
  usedPoints: number = 0;
  freeBonusPoints: number = this.maxPoints;
  showBonusPoints: boolean = false;

  bonusSelection: boolean = true;
  moneyBonusSelected: boolean = false;
  minDate: Date = new Date();
  maxDate: Date;

  dateRange: boolean = false;

  formsOfPayment: FormOfPayment[] = [];
  allFormsOfPayment: BaseData[] = [];
  paymentFrequencies: BaseData[] = [];
  allPaymentFrequencies: BaseData[] = [];
  salutations: BaseData[] = [];

  bonuses: Bonus[] = [];
  bonusesToShow: Bonus[] = [];
  additionalData: SubExtendedDataField[] = [];
  leftSide: number = 66;
  rightSide: number = 33;

  @ViewChild('offerContainer') offerContainer: ElementRef;

  stepperOrientation: StepperOrientation = 'horizontal';

  selectedBonus: Bonus = null;
  selectedBillingAddress = null;
  selectedBonusAddress = null;
  selectedDeliveryAddress = null;

  selectedFormOfPayment = null;
  selectedPaymentFrequency = null;
  selectedStartDate = null;
  redirectUrl = null;
  clientNoUrl = null;
  startDateUrl = null;
  urlParams : Map<string, string> = new Map<string, string>;
  isWinback: boolean = false;

  calculatingPrice: boolean = false;
  creatingOrder: boolean = false;
  hideLoginBox = false;
  onlyOneAddress = false;

  checkBoxes = false;

  maxBonuses: number = 6;
  bonusReload: number = 6;

  showBonusAddress: boolean = true;

  privacyDisclaimerAgbInOne: boolean = false;

  showSEPABox: boolean = false;

  hasError = false;

  showBackToOverviewButton: boolean;

  protected readonly document = typeof document === 'object' ? document : null; // doc n.a. in SSR

  private static lastEmailInput = '';
  private registerPasswordVisibleConf = false;
  public registerPasswordVisible = false;
  private passwordComplexity = null;
  private passwordInputValidators: ValidatorFn[] = [Validators.required];

  constructor(
    private formBuilder: FormBuilder,
    private baseDataService: BaseDataService,
    private loginService: LoginService,
    private offerService: OfferService,
    private hupSubscriptionsService: HupSubscriptionsService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public translationService: TranslationService,
    public dialog: MatDialog,
    private configService: ConfigService,
    private snackBar: SnackbarService,
    public vHostService: VHostService,
    private navigationService: NavigationService,
    private moveService: MoveService,
    private paymentTransactionService: PaymentProviderService,
    private datePipe: DatePipe,
    private orderTrackingService: OrderTrackingService,
    private sanitizer: DomSanitizer,
    private imageService: ImageService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isLoadingView = true;
    this.allPaymentFrequencies = this.baseDataService.getBaseData('invoicemethods');
    this.allFormsOfPayment = this.baseDataService.getBaseData('paymentmethods');
    this.salutations = this.baseDataService.getBaseData('clienttypes');
    this.configService.loadConfig('offer.maxBonusCount').subscribe(config => {
      if (config && config.value) {
        this.maxBonuses = Number(config.value);
        this.bonusReload = this.maxBonuses;
      }
    });

    this.configService.loadConfig('offer.askForBirthday').subscribe(config => {
      if (config && config.value) {
        this.askForBirthday = (config.value === 'true' || config.value === '1');
      }
    });

    this.configService.loadConfig('order.showSEPABox').subscribe(config => {
      if (config && config.value) {
        this.showSEPABox = (config.value === 'true' || config.value === '1');
      }
    });

    this.configService.loadConfig('offer.hideLoginWhileOffer').subscribe(config => {
      if (config && config.value) {
        this.hideLoginBox = (config.value === '1');
      }
    });

    this.configService.loadConfig('offer.onlyOneAddress').subscribe(config => {
      if (config && config.value) {
        this.onlyOneAddress = (config.value === '1');
      }
    });

    this.configService.loadConfig('show.backToOverView.button').subscribe(config => {
      if (config && config.value) {
        this.showBackToOverviewButton = (config.value === '1');
      }
    });

    this.configService.loadConfig('offer.showBonusAddress').subscribe(config => {
      if (config && config.value) {
        this.showBonusAddress = (config.value === 'true' || config.value === '1');
      }
    });

    this.configService.loadConfig('offer.register.showPassword').subscribe(config => {
      if (config && config.value) {
        this.registerPasswordVisibleConf = (config.value === '1');
      }
    });

  }

  stepperSelectionChanged(event: StepperSelectionEvent) {
    // Wenn wir im Stepper zurück gehen, muss der Preis gelöscht werden..
    if (event.previouslySelectedIndex > event.selectedIndex) {
      this.offerPrice = null;
    }
  }

  ngAfterContentChecked(): void {

  }

  ngAfterContentInit(): void {

  }

  onResize(event): void {
    this.calculateWidth();
  }


  ngAfterViewChecked(): void {
    this.calculateWidth();
  }

  calculateWidth(): void {
    const width = this.offerContainer?.nativeElement.offsetWidth;
    if (width) {
      this.colAmount = (width >= 768) ? 3 : 2;
      this.stepperOrientation = width <= 700 ? 'vertical' : 'horizontal';
      this.leftSide = width <= 850 ? 100 : 66;
      this.rightSide = width <= 850 ? 100 : 33;
    }
  }

  ngOnInit(): void {
    this.countries = this.baseDataService.getBaseData('countrycodes');
    this.buildBonusForm();
    this.buildAddressForm();
    this.buildRegisterForm();
    this.buildPaymentForm();

    this.setValidationChangeListener();
    this.setAsyncValidators();

    this.route.queryParamMap.subscribe((queryParams) => {
      this.redirectUrl = queryParams.get("redirect_URL");
    });

    this.route.queryParamMap.subscribe((queryParams) => {
      this.startDateUrl = queryParams.get("startDate");
      if (this.startDateUrl) {
        this.minDate = new Date(this.startDateUrl);
      }
    });

    this.route.queryParamMap.subscribe((queryParams) => {
      this.configService.loadConfig('offer.savedQueryParams').subscribe(paramsToSave => {
        paramsToSave?.value.split(',').forEach(param => {
          if (queryParams.has(param)) {
            this.urlParams.set(param, queryParams.get(param));
          }
        });
      });
    });

    this.setPasswordValidators();

    //*
    this.route.paramMap.subscribe((params) => {
      const offerId = params.get('offerId');
      if (offerId && Number.isInteger(+offerId)) {
        this.offerService.getOfferDetail(+offerId).subscribe(offer => {
          this.handleOffer(offer);
        });
      }
    });
    //*/
  }

  handleOffer(offer: Offer): void {
    if (offer) {
      this.offer = offer;
      this.formsOfPayment = [];
      offer.offerPaymentOfferType?.forEach(formOfPayment => {
        const foundFormOfPayment = this.allFormsOfPayment.find(searchFormOfPayment => searchFormOfPayment.key === formOfPayment.paymentMethod);
        if (foundFormOfPayment && foundFormOfPayment.useInFE && formOfPayment.paymentFrequencyList?.length > 0) {
          this.imageService.getImagePaymentType(formOfPayment.paymentMethod).subscribe(image => {
            formOfPayment.image = image;
            if(image.file){
              formOfPayment.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + image.type + ';base64,' + image.file);
            }
          })
          formOfPayment.description = foundFormOfPayment.description;
          //TODO wieder ausbauen wenn die richtige Lösung da ist!
          const newDescription = this.translationService.getTranslation('paymentcode.' + foundFormOfPayment.key);
          if (newDescription && newDescription !== '') {
            formOfPayment.description = newDescription;
          }
          this.formsOfPayment.push(formOfPayment);
        }
      });

      this.bonusSelection = false;
      if (offer.bonusList) {
        this.bonuses = [...offer.bonusList];
        if (this.bonuses.length > 0) {
          this.bonusSelection = true;
        }
      }
      if(!this.bonusSelection) {
        this.setValidatorsAtIBAN(false, true)
      }

      if (offer.requiredAdditionalData) {
        offer.requiredAdditionalData.forEach(
          extendedData => {
            extendedData.dataSets.forEach(
              dataSet => {
                const control = new FormControl(dataSet.value);
                dataSet.itemkey = extendedData.itemkey;
                (this.paymentFormGroup.get('extendedData') as FormArray).push(control);
                this.additionalData.push(dataSet);
              }
            )
          }
        );
      }

      this.authSub = this.loginService.getAuthData().subscribe((authData) => {
        this.isAuthenticated = !!authData && (authData.token !== null);
        if (this.isAuthenticated) {
          this.loginService.getUserData().subscribe(userDataSso => {
            this.moveService.getUser(userDataSso.userBackendId).subscribe({
              next : user => {
                if (user) {
                  if(userDataSso.emailList.length > 0){
                    user.emailList = userDataSso.emailList
                  }
                  this.handleUserData(user);
                }
              },
              error: () => {
                userDataSso.person = null;
                userDataSso.address = null;
                userDataSso.mobileList = [];
                userDataSso.phoneList = [];

                this.handleUserData(userDataSso);
              }
            });
          });
        }
      });

      this.hupSubscriptionsService.getNextDeliveryDate(offer.product.productCode, offer.product.variantCodeList[0]).subscribe(changeDate => {
        if(offer.offerType.ePaper) { changeDate = new Date() }
        const now = new Date();
        const tempDate = now.getTime() <= changeDate.getTime() ? changeDate : now;
        this.minDate = tempDate > this.minDate ? tempDate : this.minDate;
      });
      this.configService.loadConfig("order.maxDelayToStart").subscribe(c => {
        if (c.value != null && Number(c.value) > 0) {
          this.maxDate = new Date();
          this.maxDate.setDate(this.minDate.getDate() + Number(c.value));
        }
      });
      this.orderTrackingService.pushEvent(GtmEnum.BEGIN_CHECKOUT, offer);
    }
  }

  handleUserData(userData: UserData): void {
    this.isLoadingUserData = false;

    this.userData = userData;
    if (this.userData?.person?.initials) {
      this.userData.person.initials = this.userData.person.initials.split('.').join('');
    }
    let country = null;
    if (!!this.userData?.address) {
      this.countries?.forEach(_country => {
        if (_country.key === this.userData.address.country.toUpperCase()) {
          country = _country;
        }
      })
    }

    if (this.offer?.requiredAddresses?.deliveryAddressRequired) {
      // // Formular vorbefüllen
      this.addressFormGroup.get('deliveryAddress.person.firstName').patchValue(this.userData.person?.firstname ?? '');
      this.addressFormGroup.get('deliveryAddress.person.lastName').patchValue(this.userData.person?.lastname ?? '');
      this.addressFormGroup.get('deliveryAddress.userData.email').patchValue(this.userData.emailList[0] ?? '');
      this.addressFormGroup.get('deliveryAddress.person.title').patchValue(this.userData.person?.title ?? '');
      this.addressFormGroup.get('deliveryAddress.person.salutation').patchValue(this.userData.person?.salutation ?? '');
      this.addressFormGroup.get('deliveryAddress.person.initials').patchValue(this.userData.person?.initials ?? '');

      //
      // // Formular vorbefüllen - debug, faul und so
      this.addressFormGroup.get('deliveryAddress.address.zipcode').patchValue(this.userData.address?.zipcode ?? '');
      if( this.userData.address?.country) {
        this.addressFormGroup.get('deliveryAddress.address.country').patchValue(country, {emitEvent: true});
      }
      this.addressFormGroup.get('deliveryAddress.address.city').patchValue(this.userData.address?.city ?? '');
      this.addressFormGroup.get('deliveryAddress.address.street').patchValue(this.userData.address?.street ?? '');
      // this.secondFormGroup.get('deliveryAddress.address.district').patchValue(userData.address?.district ?? '');
      let houseNoParts = userData.address?.houseno.split('#');
      if( '01' === houseNoParts?.shift() ) {
        this.addressFormGroup.get('deliveryAddress.address.houseNo').patchValue(houseNoParts[0] ?? '');
        this.addressFormGroup.get('deliveryAddress.address.stock').patchValue(houseNoParts[1] ?? '');
        this.addressFormGroup.get('deliveryAddress.address.stiege').patchValue(houseNoParts[2] ?? '');
        this.addressFormGroup.get('deliveryAddress.address.tuer').patchValue(houseNoParts[3] ?? '');
      } else {
        this.addressFormGroup.get('deliveryAddress.address.houseNo').patchValue(houseNoParts[0] ?? '');
      }
      this.setValidatorsAtForm('deliveryAddress', true);
    }
    if (this.offer?.requiredAddresses?.billingAddressRequired && !this.offer?.requiredAddresses?.deliveryAddressRequired) {
      // // Formular vorbefüllen
      this.addressFormGroup.get('billingAddress.person.firstName').patchValue(this.userData.person?.firstname ?? '');
      this.addressFormGroup.get('billingAddress.person.lastName').patchValue(this.userData.person?.lastname ?? '');
      this.addressFormGroup.get('billingAddress.userData.email').patchValue(this.userData.emailList[0] ?? '');
      this.addressFormGroup.get('billingAddress.person.title').patchValue(this.userData.person?.title ?? '');
      this.addressFormGroup.get('billingAddress.person.salutation').patchValue(this.userData.person?.salutation ?? '');
      this.addressFormGroup.get('billingAddress.person.initials').patchValue(this.userData.person?.initials ?? '');
      //
      // // Formular vorbefüllen - debug, faul und so
      this.addressFormGroup.get('billingAddress.address.zipcode').patchValue(this.userData.address?.zipcode ?? '');
      if (this.userData.address?.country) {
        this.addressFormGroup.get('billingAddress.address.country').patchValue(country);
      }
      this.addressFormGroup.get('billingAddress.address.city').patchValue(this.userData.address?.city ?? '');
      this.addressFormGroup.get('billingAddress.address.street').patchValue(this.userData.address?.street ?? '');
      // this.secondFormGroup.get('deliveryAddress.address.district').patchValue(userData.address?.district ?? '');
      let houseNoParts = userData.address?.houseno.split('#');
      if( '01' === houseNoParts?.shift() ) {
        this.addressFormGroup.get('billingAddress.address.houseNo').patchValue(houseNoParts[0] ?? '');
        this.addressFormGroup.get('billingAddress.address.stock').patchValue(houseNoParts[1] ?? '');
        this.addressFormGroup.get('billingAddress.address.stiege').patchValue(houseNoParts[2] ?? '');
        this.addressFormGroup.get('billingAddress.address.tuer').patchValue(houseNoParts[3] ?? '');
      } else {
        this.addressFormGroup.get('billingAddress.address.houseNo').patchValue(houseNoParts[0] ?? '');
      }
    } else {
      this.setValidatorsAtForm('billingAddress', false);
    }

    this.paymentFormGroup.get('invoiceEmail').patchValue(this.userData.emailList?.at(0) ?? '');
  }

  setAsyncValidators(): void {
    this.paymentFormGroup.get('iban').setAsyncValidators(CustomValidators.validateIBAN(
      this.http,
      this.translationService,
      this.paymentFormGroup.get('bic')
    ));
    this.paymentFormGroup.get('ibanBonus').setAsyncValidators(CustomValidators.validateIBAN(
      this.http,
      this.translationService,
      this.paymentFormGroup.get('bicBonus')
    ));
    this.addressFormGroup.get('email').setAsyncValidators(CustomValidators.checkEmailAddress(
      this.http,
      this.translationService,
    ));
  }

  setValidationChangeListener(): void {
    this.addressFormGroup.get('billingAddress.showAddress').valueChanges.subscribe(value => {
      this.setValidatorsAtForm('billingAddress', value);
    });

    this.addressFormGroup.get('bonusAddress.showAddress').valueChanges.subscribe(value => {
      this.setValidatorsAtForm('bonusAddress', value);
    });

    this.paymentFormGroup.get('formOfPayment').valueChanges.subscribe(value => {
      this.handleFormOfPaymentChange(value);
    });

    this.paymentFormGroup.get('paymentFrequency').valueChanges.subscribe(value => {
      this.selectedPaymentFrequency = value;
    });

    this.paymentFormGroup.get('startdate').valueChanges.subscribe(value => {
      this.selectedStartDate = value;
    });

    this.loginFormGroup.get('email').valueChanges.subscribe(value => {
      this.addressFormGroup.get('deliveryAddress.userData.email').patchValue(value);
      this.addressFormGroup.get('billingAddress.userData.email').patchValue(value);
      this.addressFormGroup.get('bonusAddress.userData.email').patchValue(value);
      this.paymentFormGroup.get('invoiceEmail').patchValue(value);
    });
  }

  private handleFormOfPaymentChange(value) {
    this.paymentFrequencies = [];
    const findFormOfPayment = this.formsOfPayment?.find(formOfPayment => formOfPayment.paymentMethod === value.paymentMethod);
    findFormOfPayment?.paymentFrequencyList?.forEach(searchFrequency => {
      const foundFrequency = this.allPaymentFrequencies.find(frequency => frequency.key === searchFrequency);
      if (foundFrequency) {
        if (findFormOfPayment.paymentType === 'DIRECTDEBITIBAN') {
          this.setValidatorsAtIBAN(true, false);
        } else {
          this.setValidatorsAtIBAN(false, false);
        }
        if (findFormOfPayment.invoiceType === 'EMAILDELIVERY') {
          this.setValidatorsAtInvoiceEmail(true);
        } else {
          this.setValidatorsAtInvoiceEmail(false);
        }
        this.paymentFrequencies.push(foundFrequency);
      }
    });
    this.selectedFormOfPayment = value;
    if (this.paymentFrequencies.length === 1) {
      this.paymentFormGroup.get('paymentFrequency').setValue(this.paymentFrequencies[0]);
    }
  }

  setValidatorsAtIBAN(value: boolean, forMoneyBonus: boolean) {
    let ibanField = 'iban';
    let bicField = 'bic';
    let sepaOptIn= 'sepaOptIn';

    if(forMoneyBonus) {
      ibanField = ibanField.concat('Bonus')
      bicField = bicField.concat('Bonus')
    }
    if (value === true) {
      this.paymentFormGroup.get(ibanField).setValidators(Validators.required);
      this.paymentFormGroup.get(bicField).setValidators(Validators.required);

      if( this.showSEPABox) {
        this.paymentFormGroup.get(sepaOptIn).setValidators(Validators.required);
      }
      this.setAsyncValidators();
      if(!forMoneyBonus) {
        this.needIbanBic = true;
      }
    } else {
      this.paymentFormGroup.get(ibanField).clearValidators();
      this.paymentFormGroup.get(ibanField).clearAsyncValidators();
      this.paymentFormGroup.get(bicField).clearValidators();
      this.paymentFormGroup.get(sepaOptIn).clearValidators();

      this.paymentFormGroup.get(ibanField).reset();
      if(!forMoneyBonus) {
        this.needIbanBic = false;
      }
    }

  }

  setValidatorsAtInvoiceEmail(value: boolean) {
    if (value === true) {
      this.paymentFormGroup.get('invoiceEmail').setValidators([Validators.email, Validators.required]);
      this.needInvoiceMailAddress = true;
    } else {
      this.paymentFormGroup.get('invoiceEmail').clearValidators();
      this.paymentFormGroup.get('invoiceEmail').reset();
      this.needInvoiceMailAddress = false;
    }
  }

  setValidatorsAtForm(addressName: string, value: boolean) {
    if (value === true) {
      this.addressFormGroup.get(addressName + '.person.salutation').setValidators(Validators.required);
      this.addressFormGroup.get(addressName + '.person.firstName').setValidators(Validators.required);
      this.addressFormGroup.get(addressName + '.person.lastName').setValidators(Validators.required);
      this.addressFormGroup.get(addressName + '.address.country').setValidators(Validators.required);
      this.addressFormGroup.get(addressName + '.address.zipcode').setValidators(Validators.required);
      this.addressFormGroup.get(addressName + '.address.city').setValidators(Validators.required);
      this.addressFormGroup.get(addressName + '.address.street').setValidators(Validators.required);
      this.addressFormGroup.get(addressName + '.address.houseNo').setValidators(Validators.required);
    } else {
      this.addressFormGroup.get(addressName + '.person.salutation').clearValidators();
      this.addressFormGroup.get(addressName + '.person.firstName').clearValidators();
      this.addressFormGroup.get(addressName + '.person.lastName').clearValidators();
      this.addressFormGroup.get(addressName + '.address.country').clearValidators();
      this.addressFormGroup.get(addressName + '.address.zipcode').clearValidators();
      this.addressFormGroup.get(addressName + '.address.city').clearValidators();
      this.addressFormGroup.get(addressName + '.address.street').clearValidators();
      this.addressFormGroup.get(addressName + '.address.houseNo').clearValidators();

      this.addressFormGroup.get(addressName + '.person.salutation').updateValueAndValidity();
      this.addressFormGroup.get(addressName + '.person.firstName').updateValueAndValidity();
      this.addressFormGroup.get(addressName + '.person.lastName').updateValueAndValidity();
      this.addressFormGroup.get(addressName + '.address.country').updateValueAndValidity();
      this.addressFormGroup.get(addressName + '.address.zipcode').updateValueAndValidity();
      this.addressFormGroup.get(addressName + '.address.city').updateValueAndValidity();
      this.addressFormGroup.get(addressName + '.address.street').updateValueAndValidity();
      this.addressFormGroup.get(addressName + '.address.houseNo').updateValueAndValidity();
    }
  }

  setPasswordValidators(): void {
    // ToDo: translations
    this.loginService.getPasswordComplexityInfo().subscribe(data => {
      if (data) {
        this.passwordComplexity = data;
        if (parseInt(data.minLength)) {
          this.passwordInputValidators.push((c: AbstractControl) => {
            const missing = Math.max(0, parseInt(data.minLength) - c.value.length);
            return missing ? {minLength: missing + ' Zeichen'} : null;
          });
        }
        if (parseInt(data.lowerChars)) {
          this.passwordInputValidators.push((c: AbstractControl) => {
            const missing = Math.max(0, parseInt(data.lowerChars) - c.value.replace(/[^a-zäöüáéíóúýàèìòùâêîôûßčěňřšůž]/g, '').length);
            return missing ? {lowerChars: missing + ' Kleinbuchstabe' + (missing == 1 ? '' : 'n')} : null;
          });
        }
        if (parseInt(data.upperChars)) {
          this.passwordInputValidators.push((c: AbstractControl) => {
            const missing = Math.max(0, parseInt(data.upperChars) - c.value.replace(/[^A-ZÄÖÜÁÉÍÓÚÝÀÈÌÒÙÂÊÎÔÛĎČĚŇŘŠŤŮŽ]/g, '').length);
            return missing ? {upperChars: missing + ' Großbuchstabe' + (missing == 1 ? '' : 'n')} : null;
          });
        }
        if (parseInt(data.digits)) {
          this.passwordInputValidators.push((c: AbstractControl) => {
            const missing = Math.max(0, parseInt(data.digits) - c.value.replace(/[^0-9]/g, '').length);
            return missing ? {digits: missing + ' Ziffer' + (missing == 1 ? '' : 'n')} : null;
          });
        }
        if (parseInt(data.specialChars)) {
          this.passwordInputValidators.push((c: AbstractControl) => {
            const missing = Math.max(0, parseInt(data.specialChars) - c.value.replace(/[0-9a-zäöüáéíóúýàèìòùâêîôûßčěňřšůžA-ZÄÖÜÁÉÍÓÚÝÀÈÌÒÙÂÊÎÔÛĎČĚŇŘŠŤŮŽ]/g, '').length);
            return missing ? {specialChars: missing + ' Sonderzeichen'} : null;
          });
        }
      }
    });
  }

  buildBonusForm(): void {
    this.bonusFormGroup = this.formBuilder.group({
      bonus: new FormArray([], Validators.required)
    });
  }

  buildAddressForm(): void {
    this.addressFormGroup = this.formBuilder.group({
      deliveryAddress: this.formBuilder.group({
        userData: this.formBuilder.group( {
          birthDay: [''],
          email: ['']
        }),
        showAddress: [''],
        address: this.formBuilder.group({
          country: [''],
          zipcode: [''],
          city: [''],
          street: [''],
          houseNo: [''],
          extraLine: [''],
          houseNoExt: [''],
          stiege: [''],
          stock: [''],
          tuer: [''],
          district: ['']
        }),
        person: this.formBuilder.group({
          title: [''],
          salutation: [''],
          firstName: [''],
          lastName: [''],
          initials: [''],
          middlename: ['']
        })
      }),
      billingAddress: this.formBuilder.group({
        userData: this.formBuilder.group({
          birthDay: [''],
          email: ['']
        }),
        showAddress: [''],
        address: this.formBuilder.group({
          country: [''],
          zipcode: [''],
          city: [''],
          street: [''],
          houseNo: [''],
          extraLine: [''],
          houseNoExt: [''],
          stiege: [''],
          stock: [''],
          tuer: [''],
          district: ['']
        }),
        person: this.formBuilder.group({
          title: [''],
          salutation: [''],
          firstName: [''],
          lastName: [''],
          initials: [''],
          middlename: [''],
        })
      }),
      bonusAddress: this.formBuilder.group({
        userData: this.formBuilder.group({
          birthDay: [''],
          email: [''],
        }),
        showAddress: [''],
        address: this.formBuilder.group({
          country: [''],
          zipcode: [''],
          city: [''],
          street: [''],
          houseNo: [''],
          extraLine: [''],
          houseNoExt: [''],
          stiege: [''],
          stock: [''],
          tuer: [''],
          district: ['']
        }),
        person: this.formBuilder.group({
          title: [''],
          salutation: [''],
          firstName: [''],
          lastName: [''],
          initials: [''],
          middlename: [''],
        })
      }),
      email: ['', [Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|.(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]],
    });

    if (!this.hideLoginBox) {
      this.addressFormGroup.get('email').clearValidators();
    }
  }

  buildPaymentForm(): void {
    this.paymentFormGroup = this.formBuilder.group({
      formOfPayment: ['', Validators.required],
      paymentFrequency: ['', Validators.required],
      iban: ['', Validators.required],
      bic: [{value: this.translationService.getTranslation('filled_automatically'), disabled: true}],
      ibanBonus: ['', Validators.required],
      bicBonus: [{value: this.translationService.getTranslation('filled_automatically'), disabled: true}],
      sepaOptIn: [''],
      invoiceEmail: ['', [Validators.email, Validators.required]],
      extendedData: new FormArray([]),
      startdate: ['', Validators.required],
      enddate: ['']
    });

  }
  buildRegisterForm(): void {
    this.loginFormGroup = this.formBuilder.group({
      email: [OfferDetailComponent.lastEmailInput.length ? OfferDetailComponent.lastEmailInput : '', [Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|.(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]],
      password: [''],
    });
    this.loginFormGroup.get('password').disable();
  }

  onCompleteOrder(orderResult: OrderResultModel): void {
    this.creatingOrder = true;
    this.showOnlinePayment = false;
    this.errorsArr = [];
    const data = this.buildDataObjectForService(false, orderResult);
    if (!this.userData || this.clientNoUrl) {
      this.hupSubscriptionsService.sendOfferUnAuthorized(data).subscribe({
        next: (res) => {
          this.orderTrackingService.pushEvent(GtmEnum.PURCHASED, res);
          const otlcCode = res.otlc ?? '';
          if(otlcCode.length){
            this.loginService.loginByOtlc(otlcCode);
          }
          // Result an der Session speichern, da wir nichts nachladen können -> User ist noch nicht da ^^
          this.creatingOrder = false;
          this.navigationService.navigateTo(NavigationCommand.OFFERORDERED, [res.body.webId, '-1', '0']).then((navigated) => {
            if (navigated) {
              this.snackBar.openSnackbar(this.translationService.getTranslation("saved"));
            }
          });
        },
        error: (error) => {

          const errTestObj = {};
          this.creatingOrder = false;
          console.error(error);
          //this.errorService.handleAPIError([this.firstFormGroup, this.secondFormGroup, this.thirdFormGroup, this.fourthFormGroup, this.fifthFormGroup], errTestObj, this.errorsArr, stepper);
        }
      });
    } else {
      this.hupSubscriptionsService.sendOffer(data).subscribe({
        next: (res) => {
          this.orderTrackingService.pushEvent(GtmEnum.PURCHASED, res);
          this.creatingOrder = false;
          this.navigationService.navigateTo(NavigationCommand.OFFERORDERED, [res.webId, res.backendId, '0']);
        },
        error: (error) => {
          this.creatingOrder = false;
          const errTestObj = {};

          console.error(error);
          // this.errorService.handleAPIError(fusingTest, error.error, this.errorsArr);
          // this.errorService.handleAPIError([this.complaintForm, this.complaintCompensationForm], error.error, this.errorsArr);
          //this.errorService.handleAPIError([this.firstFormGroup, this.secondFormGroup, this.thirdFormGroup, this.fourthFormGroup, this.fifthFormGroup], errTestObj, this.errorsArr, stepper);
        }
      });
    }
  }

  getBonusControls(): AbstractControl[] {
    return (this.bonusFormGroup.get('bonus') as FormArray).controls;
  }

  onSelectBonus(bonus: Bonus, element: HTMLElement): void {
    if (!bonus.bonusValue || bonus.bonusValue < 1) {
      bonus.bonusValue = 12;
    }
    if (this.freeBonusPoints >= bonus.bonusValue) {
      const control = new FormControl(bonus);
      (this.bonusFormGroup.get('bonus') as FormArray).push(control);
      this.selectedBonus = bonus;
      this.moneyBonusSelected = bonus?.bonusType == BonusType.MONEY_BONUS;
      this.setValidatorsAtIBAN(this.moneyBonusSelected, true)
      this.calcBonusPoints();
    }

    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
    }
  }

  onDeselectBonus(index: number): void {
    (this.bonusFormGroup.get('bonus') as FormArray).removeAt(index);
    this.calcBonusPoints();
    this.selectedBonus = null;
    this.moneyBonusSelected = false;
  }

  getAdditionalDataControls(): AbstractControl[] {
    return (this.paymentFormGroup.get('extendedData') as FormArray).controls;
  }

  onSelectAddresses() {
    const data = this.buildDataObjectForService(true)
    const billingAddress = data.billingAddress;
    if (billingAddress) {
      this.selectedBillingAddress = billingAddress;
      this.selectedBillingAddress.required = this.offer.requiredAddresses.billingAddressRequired;
    }
    const deliveryAddress = data.deliveryAddress;
    if (deliveryAddress) {
      this.selectedDeliveryAddress = deliveryAddress;
    }
    const bonusAddress = data.bonusAddress;
    if (bonusAddress) {
      this.selectedBonusAddress = bonusAddress;
      this.selectedBonusAddress.required = this.bonusSelection;
    }
    this.orderTrackingService.pushEvent(GtmEnum.ADDED_ADDRESSES, data );
  }

  setUniquePaymentInfos(): void {
    if (this.formsOfPayment.length == 1) {
      this.paymentFormGroup.get('formOfPayment').setValue(this.formsOfPayment[0]);
      this.handleFormOfPaymentChange(this.formsOfPayment[0]);
    }
  }

  onSelectFormOfPayment(formOfPayment) {
    this.selectedFormOfPayment = formOfPayment;
    if (formOfPayment) {
      this.paymentFormGroup.get('formOfPayment').patchValue(formOfPayment);
    } else {
      this.paymentFormGroup.get('formOfPayment').patchValue(null);
    }
  }

  calcBonusPoints(): void {

    let usedPoints = 0;

    (this.bonusFormGroup.get('bonus') as FormArray).controls.forEach((bonus) => {
      usedPoints += bonus.value.bonusValue;
    });

    this.usedPoints = usedPoints;
    this.freeBonusPoints = this.maxPoints - usedPoints;

  }

  onShowDetails(bonus: Bonus): void {
    const dialogRef = this.dialog.open(BonusDetailComponent, {
      data: {bonus, showBonusPoints: false}
    });
  }

  calcPriceOfSubscription(): void {
      if (this.offer.priceCalculationActivated) {
        this.calculatingPrice = true;
        const data = this.buildDataObjectForService(false);
        this.orderTrackingService.pushEvent(GtmEnum.ADDED_PAYMENT, data);
        this.hupSubscriptionsService.getPriceForSubscription(data).subscribe({
          next: (prices) => {
            const data = this.buildDataObjectForService(false);
            this.orderTrackingService.pushEvent(GtmEnum.CALCULATED_PRICE, prices);
            this.calculatingPrice = false;
            const period = {
              validFrom: data.validDate.validFrom,
              validUntil: null
            };
            const calculatedPrices = [];
            prices.forEach(price => {
              if (price.trial) {
                const trialPrice = new OfferPrice(data.quantity, period, data.currency, price.price, null, true);
                calculatedPrices.push(trialPrice);
              } else {
                if ( price.previewPrice) {
                  const resultPrice: OfferPrice = new OfferPrice(data.quantity, period, price.previewPrice.currency, price.previewPrice.price, this.offer.priceCalculationSummary, false);
                  calculatedPrices.push(resultPrice);
                } else if ((price.price > 0) || calculatedPrices.length == 0){
                  const resultPrice: OfferPrice = new OfferPrice(data.quantity, period, price.currency, price.price, this.offer.priceCalculationSummary, false);
                  calculatedPrices.push(resultPrice);
              }
            }
          });
          const displayPricesAsSum = this.configService.getConfig('order.price.combiPricesAsSum')
            if( (displayPricesAsSum?.value === '1' || displayPricesAsSum?.value === 'true') && calculatedPrices.length > 1 ) {
              let price : number = 0.0;
              calculatedPrices.forEach( pricePart => price = price + pricePart.price );
              calculatedPrices[0].price = price;
              this.offerPrice = [ calculatedPrices[0] ]
            } else {
              this.offerPrice = calculatedPrices;
            }
          const transaction = new PaymentTransactionModel();
          transaction.amount = calculatedPrices[0].price;
          transaction.invoiceText = 'OfferId: ' + this.offer.recno + '';
          const paymentCode = data?.payment?.paymentType?.paymentMethod;
          const paypalPaymentCode = this.configService.getConfig('paymentprovider.paypal.paymentcode');
          const buckarooPaymentCode = this.configService.getConfig('paymentprovider.buckaroo.paymentcode');
          if (paymentCode === paypalPaymentCode?.value) {
            transaction.provider = PaymentProvider.PAYPAL;
            this.showOnlinePayment = true;
          } else if (paymentCode == buckarooPaymentCode?.value) {
            transaction.provider = PaymentProvider.BUCKAROO;
            transaction.orderObject = this.buildDataObjectForService(false);
            this.showOnlinePayment = true;
          }
          if (this.showOnlinePayment) {
            this.paymentTransactionService.setNewTransaction(transaction);
          }
        },
          error: (error) => {
            this.calculatingPrice = false;
            this.hasError = true;
            console.error(error);
          }})
      } else {
        this.offerPrice = [new OfferPrice(1, {
          validFrom: new Date(),
          validUntil: new Date()
        }, null, null, this.offer.priceCalculationSummary, false)];
      }
  }
  buildDataObjectForService(forView: boolean, orderResult: OrderResultModel = null): any {
      const transaction = orderResult?.paymentTransaction;
    const bonusData = JSON.parse(JSON.stringify(this.bonusFormGroup.value));
    const addresses = JSON.parse(JSON.stringify(this.addressFormGroup.getRawValue()));
    const additionalDataValues = JSON.parse(JSON.stringify(this.paymentFormGroup.value));
    const paymentInfos = JSON.parse(JSON.stringify(this.paymentFormGroup.value));
    const bonusPaymentInfos = JSON.parse(JSON.stringify(this.paymentFormGroup.value));
    const dateAndAGBs = JSON.parse(JSON.stringify(this.paymentFormGroup.value));
    const register = JSON.parse(JSON.stringify(this.loginFormGroup.value));
    let billingAddress = this.buildAddressList(this.offer.requiredAddresses.billingAddressRequired, addresses.billingAddress, dateAndAGBs.startdate, null, forView);
    let bonusAddress = this.buildAddressList(this.bonusSelection, addresses.bonusAddress, dateAndAGBs.startdate, null, forView);
    let deliveryAddress = this.buildAddressList(this.offer.requiredAddresses.deliveryAddressRequired, addresses.deliveryAddress, dateAndAGBs.startdate, null, forView);
    let birthday = addresses?.deliveryAddress?.userData?.birthDay;
    if (!birthday && birthday === '') {
      birthday = addresses?.billingAddress?.userData?.birthDay;
    }
    if (!birthday && birthday === '') {
      birthday = addresses?.bonusAddress?.userData?.birthDay;
    }
    const payment = this.buildPaymentInfo(paymentInfos);

    if (!this.offer.requiredAddresses.deliveryAddressRequired &&
      this.offer.requiredAddresses.billingAddressRequired) {
      deliveryAddress = billingAddress;
    }

    if (this.addressFormGroup.get('billingAddress.showAddress').value === '') {
      if (forView) {
        billingAddress = null;
      } else if (this.offer.requiredAddresses.deliveryAddressRequired) {
        billingAddress = deliveryAddress;
      }

    }
    if (!this.offer.requiredAddresses.deliveryAddressRequired &&
      this.offer.requiredAddresses.billingAddressRequired) {
      deliveryAddress = billingAddress;
    }

    if (this.addressFormGroup.get('bonusAddress.showAddress').value === '' && this.bonusSelection && !forView) {
      bonusAddress = deliveryAddress;
    }

    let deliveryAddressEmail = addresses?.billingAddress?.userData?.email;
    if(deliveryAddressEmail == null || deliveryAddressEmail.trim() == ""){
      deliveryAddressEmail = addresses?.deliveryAddress?.userData?.email;
    }
    let data: any = {
      offerId: this.offer?.recno,
      quantity: 1,
      billingAddress,
      deliveryAddress,
      validDate: {
        validFrom: this.datePipe.transform(dateAndAGBs.startdate, 'yyyy-MM-dd')
      },
      payment,
      userAgreement: {},
      email: deliveryAddressEmail && deliveryAddressEmail !== '' ? deliveryAddressEmail: addresses.email,
      registerPassword: register?.password ?? null,
      userBackendId: this.userData?.userBackendId ?? -1,
    };
    if (bonusData && bonusData.bonus.length > 0) {
      if(bonusData.bonus[0].bonusType === BonusType.MONEY_BONUS) {
        if (bonusPaymentInfos.ibanBonus) {
          let objBank: any;
          objBank = {
            swiftIBAN: bonusPaymentInfos.ibanBonus
          }
          bonusData.bonus[0].bankAccount = objBank;
        }
      }
      data.selectedGiftList = bonusData.bonus;
    }
    if (bonusAddress) {
      data.bonusAddress = bonusAddress;
    }
    if (additionalDataValues.extendedData) {
      let index = 0;
      let extendedData: SubExtendedData[] = [];
      additionalDataValues.extendedData.forEach(value => {
        let currentDataSet = this.additionalData[index];
        currentDataSet.value = value;
        let found = false;
        extendedData.forEach(data => {
          if(data.itemkey == currentDataSet.itemkey) {
            data.dataSets.push(currentDataSet);
            found = true;
          }
        })
        if(!found) {
          let newData = new SubExtendedData();
          newData.itemkey = this.additionalData[index].itemkey;
          newData.dataSets.push(currentDataSet);
          extendedData.push(newData)
        }
        index++;
      });
      data.additionalData = extendedData;
    }
    if (this.redirectUrl) {
      data.redirectUrl = this.redirectUrl;
    }
    data.userAgreement.checkAGB = orderResult?.agb ?? true;
    data.userAgreement.checkPrivacy = orderResult?.privacy ?? true;
    data.userAgreement.checkWiderruf = orderResult?.disclaimer ?? true;
    if (birthday) {
      birthday = this.datePipe.transform(birthday, 'yyyy-MM-dd')
      data.birthday = birthday;
    }
    // transaction ans Auftragsobjekt schreiben, damit diese Daten übernommen werden in die SUB_ONLINEPAYMENT
    if (transaction) {
      data.payment.onlinePayment = {};
      data.payment.onlinePayment.transactionId = transaction.transactionId
      data.payment.onlinePayment.transactionProvider = transaction.provider;
      data.payment.onlinePayment.amount = transaction.amount;
      data.payment.onlinePayment.transactionInfo = transaction.invoiceText;
    }
    data.additionalOfferParams = Object.fromEntries(this.urlParams);
    return data;
  }

  buildPaymentInfo(paymentInfos: any): any {
    let objBank: any;
    let payment: any = {};
    let paymentType: any;

    if (paymentInfos.iban) {
      objBank = {
        swiftIBAN: paymentInfos.iban
      }
      payment.bank = objBank;
    }
    paymentType = {
      paymentMethod: paymentInfos.formOfPayment.paymentMethod,
      paymentFrequency: paymentInfos.paymentFrequency.key
    }
    payment.invoiceEmail = paymentInfos.invoiceEmail;
    payment.paymentType = paymentType;

    return payment;
  }

  isString(x): boolean {
    return Object.prototype.toString.call(x) === "[object String]"
  }

  buildAddressList(required: boolean, addressFormJson, validFrom: string, validUntil: string, forView: boolean): any {
    let subscriptionAddress = null;
    let initials = '';
    if (!addressFormJson.person.initials?.includes('.')) {
      addressFormJson?.person?.initials ? addressFormJson.person.initials.split('').forEach(letter => initials = initials + letter.toUpperCase()) : '';
    } else {
      initials = addressFormJson.person.initials.toUpperCase();
    }
    if (required) {
      let salutation = null;
      salutation = this.salutations.filter(sal => sal.key === addressFormJson?.person?.salutation);
      subscriptionAddress = {
        person: {
          firstname: addressFormJson?.person?.firstName,
          lastname: addressFormJson?.person?.lastName,
          email: addressFormJson?.userData?.email,
          title: this.isString(addressFormJson?.person?.title) ? addressFormJson?.person?.title : (forView ? addressFormJson?.person?.title?.description : addressFormJson?.person?.title?.key),
          salutation: salutation && salutation.length > 0 ? forView ? salutation[0].description : salutation[0].key : this.isString(addressFormJson?.person?.salutation) ? addressFormJson?.person?.salutation : forView ? addressFormJson?.person?.salutation?.description : addressFormJson?.person?.salutation?.key,
          initials: initials,
          middlename: addressFormJson?.person.middlename
        },
        address: {
          street: addressFormJson?.address?.street,
          houseno: this.buildHouseNo(addressFormJson, forView),
          zipcode: addressFormJson?.address?.zipcode,
          city: addressFormJson?.address?.city,
          addrExtraLine1: addressFormJson?.address?.extraLine,
          country: this.isString(addressFormJson?.address?.country) ? addressFormJson?.address?.country : (forView ? addressFormJson?.address?.country?.description : addressFormJson?.address?.country?.key),
          district: addressFormJson?.address?.district
        },
        validDate: {
          validFrom,
          validUntil
        },
        userBackendId: this.userData?.userBackendId ?? -1
      };
    }

    return subscriptionAddress;
  }

  buildHouseNo(addressFormJson, forView : boolean) : string {
    let houseNoString = '';

    let isAustria = this.isString(addressFormJson?.address?.country) ?
      addressFormJson?.address?.country === 'A' :
      addressFormJson?.address?.country?.key === 'A';
    if(!forView) {
      houseNoString = isAustria ? '01#' : '00#';
    }
    let separator = forView? '/' : '#'
    houseNoString = houseNoString + addressFormJson?.address?.houseNo;
    if ( isAustria ) {
      houseNoString = houseNoString +
        separator + addressFormJson?.address?.stiege +
        separator + addressFormJson?.address?.stock +
        separator + addressFormJson?.address?.tuer;
    } else {
      if ( !forView) {
        houseNoString = houseNoString + '###'
      }
    }

    return houseNoString;
  }

  onClickEvent(): void {
    this.loginComponent.onClickEvent();
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
  }

  backToOverview(): void {
    this.navigationService.navigateTo(NavigationCommand.OFFER, null, true);
  }

  setStartDate(): void {
    this.paymentFormGroup.controls['startdate'].setValue(this.minDate);
    this.selectedStartDate = this.minDate;
  }

  loadMoreBonuses(): void {
    this.maxBonuses = this.maxBonuses + this.bonusReload;
  }

  ngAfterViewInit(): void {
    this.isWinback = false;
    console.debug('View is being initialized');
    setTimeout(() => {
      console.debug('View is fully loaded');
      this.route.queryParamMap.subscribe((queryParams) => {
        this.clientNoUrl = queryParams.get("clientNo");
        if (this.clientNoUrl) {
          this.moveService.getUser(this.clientNoUrl).subscribe(user => {
            this.isWinback = true;
            this.isLoadingUserData = false;
            this.isAuthenticated = !!user;
            this.handleUserData(user);
          });
        }
      });
      if(isPlatformBrowser(this.platformId)) {
        this.isLoadingView = false;
      }
    }, 0);

    /*
    this.route.paramMap.subscribe((params) => {
      const offerId = params.get('offerId');
      if (offerId && Number.isInteger(+offerId)) {
        this.offerService.getOfferDetail(+offerId).subscribe(offer => {
          this.handleOffer(offer);
        });
      }
    });
    //*/

    this.configService.loadConfig('offer.privacyDisclaimerAgbInOneField').subscribe(config => {
      this.privacyDisclaimerAgbInOne = config?.value === '1';
    });
  }
  onCardClick(formOfPayment: FormOfPayment): void {
    if (formOfPayment) {
      this.paymentFormGroup.get('formOfPayment').patchValue(formOfPayment);
    } else {
      this.paymentFormGroup.get('formOfPayment').patchValue(null);
    }
  }

  onRegisterNext($event, stepper: MatStepper): void {
    const passwordControl = this.loginFormGroup.get('password');
    const emailControl = this.loginFormGroup.get('email');
    if (!this.registerPasswordVisible) {
      emailControl.markAsPending();
    }
    const emailToCheck = emailControl.value;
    OfferDetailComponent.lastEmailInput = emailToCheck;

    CustomValidators.checkIfEmailExists(emailToCheck, this.http, this.translationService)
      .subscribe(data => {
        emailControl.updateValueAndValidity(); // hide input-spinner
        if (data !== null) {
          this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url, prefillUsername: emailToCheck}})
            .then(() => null);
        } else {
          this.registerPasswordVisible = this.registerPasswordVisibleConf && true;
          if (this.registerPasswordVisible) {
            let firstTry = !passwordControl.hasValidator(Validators.required);
            if (!firstTry) {
              passwordControl.updateValueAndValidity({onlySelf: true});
            }
            if (passwordControl.valid) {
              stepper.next();
            } else if (firstTry) {
              setTimeout(() => {
                passwordControl.enable();
                passwordControl.setValidators(this.passwordInputValidators);
                passwordControl.setErrors(null);
              }, 0);
            }
          } else {
            stepper.next();
          }
        }
      });
  }


  /**
   * transferiert die IBAN vom IBAN-Feld zum Barprämien-IBAN-Feld, um dem User den Bestellprozess zu erleichtern.
   * Funktioniert NUR in eine Richtung- es ist immer noch möglich die IBAN im Prämienfeld anzupassen
   */
  transferIban() {
    if(this.moneyBonusSelected) {
      this.paymentFormGroup.get('ibanBonus').setValue(this.paymentFormGroup.get('iban').getRawValue());
    }
  }

  getPasswordComplexityHint(): string {
    let firstPart = '', middlePart = [], lastPart = '';
    const pwCpx = this.passwordComplexity;

    // ToDo: translations
    if (pwCpx) {
      parseInt(pwCpx.minLength) ? firstPart = 'mindestens ' + pwCpx.minLength + ' Zeichen' : null;
      parseInt(pwCpx.lowerChars) ? middlePart.push(pwCpx.lowerChars + ' Kleinbuchstaben') : null;
      parseInt(pwCpx.upperChars) ? middlePart.push(pwCpx.upperChars + ' Großbuchstaben') : null;
      parseInt(pwCpx.digits) ? middlePart.push(pwCpx.digits + ' Ziffern') : null;
      parseInt(pwCpx.specialChars) ? middlePart.push(pwCpx.specialChars + ' Sonderzeichen') : null;
      lastPart = middlePart.length > 1 ? middlePart.pop() : lastPart;
    }

    return firstPart + (firstPart.length && middlePart.length ? ', bestehend aus ' : '') + middlePart.join(', ') + (lastPart.length ? ' und ' : '') + lastPart;
  }

  getPasswordErrorMessage(): string {
    let msg = [];
    const pw = this.loginFormGroup.get('password');
    ['minLength', 'lowerChars', 'upperChars', 'digits', 'specialChars'].forEach( k => {
      pw.hasError(k) ? msg.push(pw.getError(k)) : null;
    });

    return (msg.length ? 'noch ' : '') + msg.join(', ');
  }
}
