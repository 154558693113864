import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../login/login.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {AuthResponseData} from "../login/auth-response-data.model";
import {TranslationService} from "../../services/translation.service";
import {isPlatformBrowser} from "@angular/common";
import {SsoConfiguration} from "../login/sso-configuration";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {HttpClient, HttpParams} from "@angular/common/http";

@Component({
  selector: 'app-login-formular',
  templateUrl: './login-formular.component.html',
  styleUrls: ['./login-formular.component.scss']
})
export class LoginFormularComponent implements OnInit {
  loginForm: FormGroup;
  loading: boolean = false;
  error: string;
  returnUrl: string = null;
  isAuthenticated: boolean = false;
  authSub: Subscription;
  authData: AuthResponseData = null;

  ssoConfiguration: SsoConfiguration;
  useHassoForm = false;
  hassoLoginUrl: SafeUrl;
  prefillUsername = '';
  public ssoFormPropagatedHeight = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private loginService: LoginService,
    private sanitizer: DomSanitizer,
    public translationService: TranslationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.ssoConfiguration = loginService.getSsoConfiguration();
    this.useHassoForm = this.ssoConfiguration.usage.toUpperCase().startsWith('OAUTHHASSO');

    this.route?.queryParams?.subscribe((params) => {
      if (params?.prefillUsername) {
        this.prefillUsername = params.prefillUsername;
      }
      if (params?.returnUrl) {
        this.returnUrl = params.returnUrl;
        this.handleRedirect();
      }
    });

    this.authSub = this.loginService?.getAuthData()?.subscribe((authData) => {
      this.authData = authData;
      this.isAuthenticated = !!authData;

      this.handleRedirect();
    });

    if (this.useHassoForm) {
      window.addEventListener("message", (event) => {
          this.loading = false;
          const eventData = typeof event.data == 'string' ? JSON.parse(event.data) : event.data;

          if (eventData.eventNature == 'ssoAjaxViewLoaded') {
            this.ssoFormPropagatedHeight = eventData.formHeight ? (eventData.formHeight + 5) + 'px' : 'inherit';
          } else {
            const alreadyLoggedIn = eventData.code === 2000;
            const justLoggedIn = typeof eventData.code == 'string' && eventData.code.match(/^[0-9a-f]{16,}$/);
            if (alreadyLoggedIn || justLoggedIn) {
              this.loginService.checkStatus();
            }
          }
        },
        false,
      );
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder?.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });

    if (this.useHassoForm && isPlatformBrowser(this.platformId)) {
      this.loading = true;

      let loginBoxParams = {
        client_id: this.ssoConfiguration.clientId,
        return_uri: encodeURIComponent(this.ssoConfiguration.returnUrl),
        responsetype: 'event',
        domain: encodeURIComponent(window?.location.origin),
        ajaxMode: 1,
        formId: 'Login',
      };
      if (this.prefillUsername.length) {
        loginBoxParams['uSrNaMe'] = this.prefillUsername;
      }

      this.hassoLoginUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.ssoConfiguration.ssoHost + '/auth/authorize?' + new HttpParams({fromObject: loginBoxParams}).toString()
      );
    }
  }

  onLogin(): void {
    if (this.loginForm?.valid) {
      this.loading = true;
      this.loginService.login(this.loginForm.get('email').value, this.loginForm.get('password').value, this.returnUrl).subscribe((res) => {
        if (res && res.token !== 0 && res.status !== 'error') {
        } else {
          this.error = res.message;
          this.loading = false;
        }
      }, error => {
        this.error = error.error;
        this.loading = false;
      });
    }
  }

  private handleRedirect(): void {
    if (this.isAuthenticated && this.returnUrl) {
      this.router.navigate([this.returnUrl],  {queryParamsHandling: "preserve"});
    }
  }

  onBack(): void {
    history.back();
  }

}
